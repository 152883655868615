export enum LocalStorageItem {
  Token = 'token',
  Visited = 'visited',
}

export class LocalStorage {
  static setToken(token: string): void {
    localStorage.setItem(LocalStorageItem.Token, token)
  }
  static getToken(): string | null {
    return localStorage.getItem(LocalStorageItem.Token)
  }
  static setVisited(): void {
    localStorage.setItem(LocalStorageItem.Visited, 'true')
  }
  static getVisited(): string | null {
    return localStorage.getItem(LocalStorageItem.Visited)
  }
}
