import React, { useEffect } from 'react'
import style from './RecognizeStageItem.module.css'
import {
  Button,
  ButtonTheme,
  ButtonSize,
  Checkbox,
  CheckboxTheme,
} from '@dbrainio/shared-ui'
import Select, { SelectItem } from '../Select/Select'
import RecognizedGrid from '../RecognizeStats/RecognizeGrid'
import { Mapper } from '../../utils/mapper'
import MagnifyImage from '../MagnifyImage/MagnifyImage'
import { downloadCsv } from '../../utils/utils'
import { v4 as uuidv4 } from 'uuid'
import { DocumentInfo } from '../../@types/api'
import { StartRecognizePayload, AppLanguage, AppState } from '../../store/types'
import { WithHitlDocumentList } from '../../utils/docFields'
import Popup from '../Popup/Popup'
import CloseButton from '../CloseButton/CloseButton'
import Container from '../Container/Container'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AdvancedParams from '../AdvancedParams/AdvancedParams'
import { IAdvancedParams } from '../AdvancedParams/AdvancedParams'
import { QuestionTooltip } from '@dbrainio/shared-ui'
import { RecognizeGridItem } from '../RecognizeStats/RecognizeGrid'
import classnames from 'classnames'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export interface RecognizeStageItemProps {
  documentInfo: DocumentInfo
  isLoading?: boolean
  progress?: number
  multi?: boolean
  onRecognize?: (payload: StartRecognizePayload) => void
}

export default function RecognizeStageItem({
  documentInfo,
  multi,
  onRecognize,
  isLoading,
  progress,
}: RecognizeStageItemProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const documentTypes = useSelector((state: AppState) => state.documentTypes)

  const [selected, setSelected] = React.useState<string>(documentInfo.docType)
  const [withHitl, setWithHitl] = React.useState<boolean>(false)
  const [isPopup, setPopup] = React.useState<boolean>(false)
  const [advanced, setAdvanced] = React.useState<IAdvancedParams>({})

  const [isVerbalConfidence, setVerbalConfidence] =
    React.useState<boolean>(true)
  const [isReadableFields, setReadableFields] = React.useState<boolean>(true)

  const getReadableSelectItems = (selected: string): SelectItem[] => {
    const items = []

    if (!isFieldExistsInDocType(selected)) {
      items.push({
        value: selected,
        text: selected,
      })
    }

    return [
      ...items,
      ...documentTypes.map((val: string) => ({
        value: val,
        text:
          language.toLowerCase() === AppLanguage.Ru
            ? Mapper.mapDocType(val)
            : val,
      })),
    ].sort(function (a, b) {
      return Intl.Collator().compare(a.text, b.text)
    })
  }

  const isFieldExistsInDocType = (selected: string) =>
    documentTypes.includes(selected)

  const handleCheckboxChange: (checked: boolean) => void = (checked) =>
    setWithHitl(checked)

  const convertToCsvRows = (items: RecognizeGridItem[]): string[][] => {
    return items.map((item: RecognizeGridItem) => [
      item.name,
      item.value,
      item.confidenceNumber + '',
    ])
  }

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onRecognize &&
          onRecognize({
            document: documentInfo,
            withHitl: withHitl,
            params: advanced,
          })
      }
    }

    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownloadCsv = () => {
    if (documentInfo.fields) {
      downloadCsv({
        rows: convertToCsvRows(
          Mapper.mapRecognizedDataToItems(
            documentInfo.fields,
            documentInfo.docType,
          ),
        ),
        filename: `${documentInfo.docType}-${uuidv4()}.csv`,
      })
    }
  }

  const isWithHitl = (docType: string) => {
    return WithHitlDocumentList.includes(docType)
  }

  return (
    <div
      className={classnames(style.root, {
        [style.multi]: multi,
      })}
    >
      <div className={style.content}>
        <div className={style.imageContainer}>
          <MagnifyImage
            src={documentInfo.crop}
            imageClassName={style.magnifyImage}
          />
        </div>

        {documentInfo.fields ? (
          Object.keys(documentInfo.fields).length ? (
            <div className={style.fields}>
              <div className={style.documentType}>
                <strong>Тип документа </strong> <br />
                {Mapper.mapDocType(documentInfo.docType)}
              </div>
              {isMobile ? (
                <>
                  <Button
                    theme={ButtonTheme.Green}
                    size={ButtonSize.L}
                    onClick={() => setPopup(true)}
                  >
                    {t('Extracted Data')}
                  </Button>
                  <Popup isOn={isPopup}>
                    <div className={style.popup}>
                      <Container>
                        <div className={style.closePopup}>
                          <CloseButton onClose={() => setPopup(false)} />
                        </div>
                        <RecognizedGrid
                          isMobile
                          docType={documentInfo.docType}
                          items={Mapper.mapRecognizedDataToItems(
                            documentInfo.fields,
                            documentInfo.docType,
                          )}
                          isReadableFields={isReadableFields}
                          isVerbalConfidence={isVerbalConfidence}
                          onToggleReadableFields={() =>
                            setReadableFields(!isReadableFields)
                          }
                          onToggleVerbalConfidence={() =>
                            setVerbalConfidence(!isVerbalConfidence)
                          }
                        />
                      </Container>
                    </div>
                  </Popup>
                </>
              ) : (
                <RecognizedGrid
                  docType={documentInfo.docType}
                  items={Mapper.mapRecognizedDataToItems(
                    documentInfo.fields,
                    documentInfo.docType,
                  )}
                  isReadableFields={isReadableFields}
                  isVerbalConfidence={isVerbalConfidence}
                  onToggleReadableFields={() =>
                    setReadableFields(!isReadableFields)
                  }
                  onToggleVerbalConfidence={() =>
                    setVerbalConfidence(!isVerbalConfidence)
                  }
                />
              )}
              <div className={style.csv}>
                <Button
                  theme={ButtonTheme.Blue}
                  size={ButtonSize.L}
                  onClick={handleDownloadCsv}
                >
                  {t('Download CSV')}
                </Button>
              </div>
            </div>
          ) : (
            <div className={style.cantRecognize}>
              {t(
                "We can't recognize this document yet, but we're already learning how to",
              )}
            </div>
          )
        ) : (
          <div className={style.manage}>
            <div className={style.grid}>
              <Select
                selected={selected}
                onChange={setSelected}
                className={style.select}
                items={getReadableSelectItems(selected)}
              />
              <AdvancedParams onChange={setAdvanced} isRecognize />
              <div className={style.actionButtons}>
                <Button
                  theme={ButtonTheme.Blue}
                  size={ButtonSize.L}
                  isLoading={documentInfo.isRecognizeLoading}
                  onClick={() =>
                    onRecognize &&
                    onRecognize({
                      document: documentInfo,
                      withHitl: withHitl,
                      params: advanced,
                    })
                  }
                >
                  {t('Extract Data')}
                </Button>
                {isWithHitl(documentInfo.docType) && (
                  <div className={style.hitl}>
                    <Checkbox
                      className={style.checkbox}
                      isChecked={withHitl}
                      onChange={handleCheckboxChange}
                      label={t('Use HITL')}
                      theme={CheckboxTheme.Black}
                    />
                    <QuestionTooltip title={t('HITL hint') || ''} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
