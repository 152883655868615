import React from 'react'
import style from './RecognizeStage.module.css'
import Container from '../Container/Container'
import { DocumentInfo } from '../../@types/api'
import RecognizeStageItem from './RecognizeStageItem'
import { StartRecognizePayload } from '../../store/types'
import classnames from 'classnames'

export interface RecognizeStageProps {
  documents: DocumentInfo[]
  images: File[]
  onRecognize: (payload: StartRecognizePayload) => void
  onReset: () => void
}

export default function RecognizeStage({
  documents,
  onRecognize,
  images,
}: RecognizeStageProps) {
  const isMultiDoc = documents.length > images.length

  return (
    <div
      className={classnames(style.root, {
        [style.multi]: documents.length > 1,
      })}
    >
      {documents.map((item: DocumentInfo, index) => {
        const crop = isMultiDoc
          ? item.crop
          : images[index] && images[index].type !== 'application/pdf'
          ? URL.createObjectURL(images[index])
          : item.crop

        return (
          <RecognizeStageItem
            multi={documents.length > 1}
            isLoading={item.isRecognizeLoading}
            progress={item.recognizeProgress}
            key={index}
            documentInfo={{
              ...item,
              crop,
            }}
            onRecognize={onRecognize}
          />
        )
      })}
    </div>
  )
}
