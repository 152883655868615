import axios from 'axios'

const MULTIKIT_API = 'https://multikit.dbrain.io'

export interface VerifyPhoneResponse {
  resultCode: number
  operationToken: string
  operationResult: 'success' | 'fail'
  operationMessage: string
}

export interface VerifyPassportResponse {
  resultCode: number
  operationToken: string
  resultMessage: string
  isDateVerified: boolean
  score: number
  description: string
}

export interface ComplexPassportVerifyPayload {
  lastName: string
  firstName: string
  midName: string
  issueDate: string
  birthDate: string
  passportNumber: string
}

export interface VerifyPhonePayload {
  lastName: string
  firstName: string
  midName: string
  phone: string
  passportNumber?: string
  birthDate?: string
}

export class IdxApi {
  static verifyPhone = (payload: VerifyPhonePayload) => {
    return axios.post<VerifyPhoneResponse>(
      `${MULTIKIT_API}/verify-phone`,
      {
        ...payload,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }
  static verifyPassport = (payload: ComplexPassportVerifyPayload) => {
    return axios.post<VerifyPassportResponse>(
      `${MULTIKIT_API}/passport`,
      {
        ...payload,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }
}
