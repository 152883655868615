import IconButton from '@mui/material/IconButton'
import { TooltipProps } from '@mui/material/Tooltip'
import React from 'react'
import { QuestionIcon } from '../QuestionIcon/QuestionIcon'
import { Tooltip } from '../Tooltip/Tooltip'

export function QuestionTooltip(props: Omit<TooltipProps, 'children'>) {
  return (
    <Tooltip {...props}>
      <IconButton>
        <QuestionIcon />
      </IconButton>
    </Tooltip>
  )
}
