import React from 'react'
import style from './Select.module.css'
import classnames from 'classnames'
// import { withStyles } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { InputBase, styled } from '@mui/material'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 24,
    position: 'relative',
    backgroundColor: '#EEEEEE',
    border: '1px solid transparent',
    fontSize: 14,
    padding: '12px 24px 12px 24px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 24,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

export interface SelectItem {
  text: string
  value: string
}

export interface DbrainSelectProps {
  selected: string
  items: SelectItem[]
  className?: string
  onChange: (value: string) => void
}

export default function DbrainSelect(props: DbrainSelectProps) {
  const handleChange = (event: any) => {
    props.onChange(event.target.value)
  }

  return (
    <FormControl className={classnames(style.formControl, props.className)}>
      <Select
        value={props.selected}
        onChange={handleChange}
        placeholder="Тип документа"
        input={<BootstrapInput />}
      >
        {props.items.map((item, index) => (
          <MenuItem value={item.value} key={index}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
