import React from 'react'
import style from './LoadingScreen.module.css'
import { Loader } from '@dbrainio/shared-ui'

interface Props {}
export default function LoadingScreen(props: Props) {
  return (
    <div className={style.root}>
      <Loader color="#1356A8" size={8} animation="jump" fullscreen />
    </div>
  )
}
