import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IAdvancedParams } from '../../components/AdvancedParams/AdvancedParams'
import Distance from '../../components/Distance/Distance'
import UploadStage from '../../components/UploadStage/UploadStage'
import { startDistance } from '../../store/actions'
import { AppState } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import style from './DistanceRoute.module.css'

interface Props {}
export default function DistanceRoute(props: Props) {
  const [isVisited] = useState(LocalStorage.getVisited())

  const token = useSelector((state: AppState) => state.token)
  const dispatch = useDispatch()
  const images = useSelector((state: AppState) => state.images)
  const distanceItems = useSelector((state: AppState) => state.distance)
  const docTypes = useSelector((state: AppState) => state.documentTypes)

  const handleDrop = (
    acceptedFiles: File[],
    docType: string,
    params: IAdvancedParams,
  ) => {
    if (acceptedFiles.length > 1) {
      dispatch(startDistance(acceptedFiles))
    }
  }

  return (
    <div className={style.root}>
      {distanceItems ? (
        <Distance items={distanceItems} images={images} />
      ) : (
        <UploadStage
          docTypes={docTypes}
          isTutorialOpen={!isVisited}
          onDrop={handleDrop}
          token={token}
        />
      )}
    </div>
  )
}
