import axios, { AxiosResponse } from 'axios'
import store from '../store'
import {
  ClassifyResponse,
  RecognizeResponse,
  RecognizePayload,
  OpenapiResponse,
  RecognizeBatchResponseData,
  SelfieResponse,
  DistanceResponse,
  InvoiceResponse,
  FulltextResponse,
  HandwrittenResponse,
  DetectFraudResponse,
  RuNerResponse, MspBankResponse,
} from '../@types/api'
import qs from 'qs'
import { chunkArray } from '../utils/utils'
import { NO_DOCUMENT } from '../utils/mapper'
import { IAdvancedParams } from '../components/AdvancedParams/AdvancedParams'

if (!window._env_.API_ENDPOINT) {
  console.warn(
    'WARNING: env.API_ENDPOINT is not defined, automatically changed to ""',
  )
}

const host = window._env_.API_ENDPOINT || ''
// const host = 'https://9080.ports.h1.dev.dbrain.io'

export interface BatchRecognizeResponseWithFile {
  response?: AxiosResponse<RecognizeBatchResponseData>
  file: File
  status?: 'rejected' | 'fulfilled'
  statusCode?: number
}

export class Api {
  static async getOpenapi() {
    const response = axios.get<OpenapiResponse>(`${host}/openapi.json`)
    return response
  }

  static async classify(
    file: File,
    params: IAdvancedParams,
  ): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<ClassifyResponse>(
      `${host}/classify`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          ...params,
          use_external_api: false,
          token: state.token,
          quality: 100,
        },
      },
    )
    return response
  }

  static async detectFraud(file: File): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<DetectFraudResponse>(
      `${host}/pipelines/fraud_detector/run`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          return_crops: true,
          token: state.token,
        },
      },
    )
    return response
  }

  static async detectFraudLocated(file: File): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<DetectFraudResponse>(
      `${host}/pipelines/fraud_detector_located/run`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          return_crops: true,
          token: state.token,
        },
      },
    )
    return response
  }

  static async ruNer(file: File): Promise<AxiosResponse<RuNerResponse>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<RuNerResponse>(
      `${host}/pipelines/runer/run`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          return_crops: true,
          token: state.token,
        },
      },
    )
    return response
  }

  static async mspBank(file: File): Promise<AxiosResponse<MspBankResponse>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = axios.post<MspBankResponse>(
        `${host}/pipelines/msp_demo/run`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            return_crops: true,
            token: state.token,
          },
        },
    )
    return response
  }


  static async selfie(
    files: File[],
    docType: string,
  ): Promise<AxiosResponse<SelfieResponse>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', files[0])

    const response = axios.post<SelfieResponse>(
      `${host}/face/selfie`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          doc_type: docType === NO_DOCUMENT ? 'bank_card' : docType,
          token: state.token, // needed?
          return_crops: false,
          mode: docType === NO_DOCUMENT ? 'simple' : 'default',
        },
      },
    )
    return response
  }

  static async distance(
    files: File[],
  ): Promise<AxiosResponse<DistanceResponse>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image1', files[0])
    formData.append('image2', files[1])

    const response = axios.post<DistanceResponse>(
      `${host}/face/distance`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          token: state.token, // needed?
        },
      },
    )
    return response
  }

  static async recognize({
    crop,
    docType,
    withHitl,
    coords,
    params,
    images,
  }: RecognizePayload): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const res = await fetch(crop)
    const blob = await res.blob()

    const coordsBlob = new Blob([JSON.stringify(coords)], {
      type: 'application/json',
    })

    var formData = new FormData()
    // if (images && images.length > 1) {
    formData.append('image', blob)
    // } else if (images && images.length) {
    //   formData.append('image', images[0])
    // }

    formData.append('coords', coordsBlob)

    return axios.post<RecognizeResponse>(`${host}/recognize`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        ...params,
        use_external_api: false,
        doc_type: docType,
        with_hitl: withHitl,
        mode: 'default',
        token: state.token,
      },
    })
  }

  static async invoice(file: File): Promise<AxiosResponse<any>> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const response = await axios.post<InvoiceResponse>(
      `${host}/recognize`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          token: state.token,
          dev_invoice_alternative_engine: true,
          use_external_api: false,
          mode: 'default',
        },
      },
    )

    return response
  }

  static async recognizeBatchFile(
    file: File,
  ): Promise<BatchRecognizeResponseWithFile> {
    const state = store.getState()

    const formData = new FormData()
    formData.append('image', file)

    const params = {
      doc_type: state.documentTypes,
      with_hitl: false,
      token: state.token,
      use_external_api: false,
    }

    try {
      const response = await axios.post(`${host}/recognize`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
        paramsSerializer: (params: any) => {
          return qs.stringify(params, { indices: false })
        },
      })
      return {
        response,
        file,
      }
    } catch (e) {
      return {
        status: 'rejected',
        file,
      }
    }
  }

  static recognizeFile(
    file: File,
    docType?: string,
  ): Promise<AxiosResponse<RecognizeResponse>> {
    const formData = new FormData()
    formData.append('image', file)

    const params: { [key: string]: any } = {
      use_external_api: false,
      quality: 100,
    }

    if (docType) {
      params.doc_type = docType
    }

    return axios.post(`${host}/recognize`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    })
  }

  static async fulltext(file: File, token: string) {
    const formData = new FormData()
    formData.append('image', file)

    const params = {
      token,
    }

    return await axios.post<FulltextResponse>(`${host}/fulltext`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    })
  }

  static async handwritten(file: File) {
    const formData = new FormData()
    formData.append('image', file)

    const params = {
      language: 'handwritten_rus',
    }

    return await axios.post<HandwrittenResponse>(
      `${host}/fulltext_by_lines`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      },
    )
  }

  static async batchRecognize(
    files: File[],
  ): Promise<BatchRecognizeResponseWithFile[]> {
    const chunks = chunkArray<File>(files, 4)
    let responses: BatchRecognizeResponseWithFile[] = []
    for (let group of chunks) {
      const promises = []
      for (let f of group) {
        promises.push(Api.recognizeBatchFile(f))
      }
      const result = await Promise.all(promises)
      responses = [...responses, ...result]
    }

    return responses
  }
}
