import React from 'react'
import Lightbox from 'react-image-lightbox'
import ReactImageMagnify from 'react-image-magnify'
import style from './MagnifyImage.module.css'
import 'react-image-lightbox/style.css'
import classnames from 'classnames'

export interface MagnifyImageProps {
  src: string
  rotation?: number
  className?: string
  imageClassName?: string
}

class MagnifyImage extends React.PureComponent<MagnifyImageProps> {
  state = {
    isLoaded: false,
    isMagnify: false,
    isFixedPosition: false,
    clientRect: undefined,
    isLightBox: false,
  }

  img: HTMLImageElement = new Image()

  componentDidMount() {
    const { src } = this.props

    this.img.addEventListener('load', this.handleLoad)
    this.img.src = src
  }

  handleLoad = () => {
    const isMagnify =
      (this.img && this.img.naturalHeight > 500) ||
      (this.img && this.img.naturalWidth > 400)
    this.setState({
      isMagnify,
      isLoaded: true,
    })
  }

  renderImage = () => {
    const { src, imageClassName, rotation } = this.props
    const { isMagnify } = this.state

    return (
      <div
        onClick={() => this.setState({ isLightBox: true })}
        className={style.container}
        style={
          {
            // transform: `rotate(${rotation}deg)`,
            // padding: rotation ? 100 : 'auto',
            // display: 'block',
            // height: '100%',
          }
        }
      >
        {this.state.isLightBox && (
          //@ts-ignore
          <Lightbox
            mainSrc={src}
            onCloseRequest={() => this.setState({ isLightBox: false })}
          />
        )}
        {isMagnify ? (
          <ReactImageMagnify
            className={style.root}
            imageClassName={classnames(style.image, imageClassName)}
            enlargedImageContainerDimensions={{
              width: 500,
              height: 400,
            }}
            enlargedImageContainerStyle={{
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
            {...{
              smallImage: {
                isFluidWidth: true,
                width: 500,
                src,
              },
              largeImage: {
                src,
                width: this.img?.naturalWidth,
                height: this.img?.naturalHeight,
              },
            }}
          />
        ) : (
          <img src={src} alt="" className={style.root} />
        )}
      </div>
    )
  }

  render() {
    return this.renderImage()
  }
}

export default MagnifyImage
