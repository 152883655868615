import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IAdvancedParams } from '../../components/AdvancedParams/AdvancedParams'
import Selfie from '../../components/Selfie/Selfie'
import UploadStage from '../../components/UploadStage/UploadStage'
import { startSelfie } from '../../store/actions'
import { AppState } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import style from './SelfieRoute.module.css'

interface Props {}
export default function SelfieRoute(props: Props) {
  const [isVisited] = useState(LocalStorage.getVisited())

  const token = useSelector((state: AppState) => state.token)
  const dispatch = useDispatch()

  const selfieItems = useSelector((state: AppState) => state.selfie)
  const images = useSelector((state: AppState) => state.images)
  const docTypes = useSelector((state: AppState) => state.documentTypes)

  const handleDrop = (
    acceptedFiles: File[],
    docType: string,
    params: IAdvancedParams,
  ) => {
    dispatch(startSelfie(acceptedFiles, docType))
  }

  return (
    <div className={style.root}>
      {selfieItems ? (
        <Selfie items={selfieItems} images={images} />
      ) : (
        <UploadStage
          docTypes={docTypes}
          isTutorialOpen={!isVisited}
          onDrop={handleDrop}
          token={token}
        />
      )}
    </div>
  )
}
