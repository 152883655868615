import React, { useEffect, useState } from 'react'
import Polygon from '../Polygon/Polygon'
import style from './MarkupedImage.module.css'

export interface IBbox {
  rCoords?: number[][]
  coords: number[][]
  isHighlighted?: boolean
  hint?: React.ReactNode
}

interface Props {
  img: string
  rotate?: number
  bboxes: IBbox[]
}

export default function MarkupedImage({ bboxes, rotate, img }: Props) {
  const [, setAspectRatio] = useState(1)
  const [imageWidth, setImageWidth] = useState(1)
  const [imageHeight, setImageHeight] = useState(1)

  useEffect(() => {
    const image = new Image()

    image.addEventListener('load', function () {
      const height = image.naturalHeight
      setImageHeight(height)
      const width = image.naturalWidth
      setImageWidth(width)
      const ratio = height / width
      setAspectRatio(ratio)
    })

    image.src = img
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <svg
        width="100%"
        viewBox={`0 0 ${imageWidth} ${imageHeight}`}
        preserveAspectRatio="none"
        className={style.root}
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          width: '100%',
          backgroundColor: 'whitesmoke',
        }}
      >
        {bboxes.map((bbox: IBbox, index) => {
          const minY = bbox.coords.reduce(
            (accum, next) => (next[1] < accum ? next[1] : accum),
            bbox.coords[0][1],
          )
          const minX = bbox.coords.reduce(
            (accum, next) => (next[0] < accum ? next[0] : accum),
            bbox.coords[0][0],
          )
          return (
            <Polygon
              key={index}
              points={bbox.coords}
              text={bbox.hint}
              fontSize={imageWidth / 32}
              textX={minX}
              textY={minY - 16}
              highlighted={bbox.isHighlighted}
            />
          )
        })}
      </svg>
    </div>
  )
}
