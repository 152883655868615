import React from "react";
import DbrainLogo from "../DbrainLogo/DbrainLogo";
import style from "./Header.module.css";
import { AppLanguage, AppState } from "../../store/types";
import HandlLogo from "../HandlLogo/HandlLogo";
import { useTranslation } from "react-i18next";
import { Hamburger } from "@dbrainio/shared-ui";
import { useSelector, useDispatch } from "react-redux";
import { setNav } from "../../store/actions";
import { isStudio } from "../../utils/env";
import Hidden from "@mui/material/Hidden";

export interface HeaderProps {
  onReset: () => void;
}
const documentationUrl = window._env_.DOCS_ENDPOINT || "/docs";
const swaggerUrl = window._env_.TRY_ENDPOINT || "/try";

const Header = ({ onReset }: HeaderProps) => {
  const isNav = useSelector((state: AppState) => state.isNav);
  const d = useDispatch();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  // Condition added for future use
  const docsUrl = language === "ru" ? documentationUrl : documentationUrl;
  const tryUrl = language === "ru" ? swaggerUrl : swaggerUrl;
  console.log("window._env_.TRY_ENDPOINT: ", window._env_.TRY_ENDPOINT);

  return (
    <div className={style.root}>
      <div className={style.content}>
        <span onClick={onReset} className={style.logo}>
          {language.toLowerCase() === AppLanguage.En ? (
            <HandlLogo light />
          ) : (
            <DbrainLogo />
          )}
        </span>
        {isStudio ? null : (
          <Hidden smUp>
            <div className={style.hamburger}>
              <Hamburger
                onClick={() => d(setNav(!isNav))}
                isOpen={isNav}
                isLight
              />
            </div>
          </Hidden>
        )}
        <Hidden smDown>
          <div className={style.links}>
            <a href={docsUrl} target="_blank" rel="noreferrer">
              {t("Documentation")}
            </a>
            <a href={tryUrl} target="_blank" rel="noreferrer">
              {t("Try API")}
            </a>
          </div>
        </Hidden>
      </div>
    </div>
  );
};

export default Header;
