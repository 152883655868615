import style from './AdvancedParams.module.css'
import {
  TextInput,
  TextInputTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  QuestionTooltip,
  Checkbox,
  CheckboxTheme,
} from '@dbrainio/shared-ui'
import React, { useState } from 'react'
import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
export interface IAdvancedParams {
  min_filesize?: number
  min_shape?: number
  min_exposure_score?: number
  max_exposure_score?: number
  max_blur_score?: number
  normalization_fias?: boolean
}

interface Props {
  isRecognize?: boolean
  onChange: (params: IAdvancedParams) => void
}

export default function AdvancedParams({ isRecognize, onChange }: Props) {
  const [isVisible, setVisible] = useState(false)
  const [min_filesize, setMinFileSize] = useState(10240)
  const [min_shape, setMinShape] = useState(256)
  const [min_exposure_score, setMinExposureScore] = useState(0.05)
  const [max_exposure_score, setMaxExposureScore] = useState(0.4)
  const [max_blur_score, setBlurScore] = useState(2)
  const [normalization_fias, setNormalizationFias] = useState(false)

  const {
    t,
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    onChange({
      min_filesize,
      min_shape,
      min_exposure_score,
      max_exposure_score,
      max_blur_score,
      normalization_fias,
    })
  }, [
    min_filesize,
    min_shape,
    min_exposure_score,
    max_blur_score,
    max_exposure_score,
    normalization_fias,
    onChange,
  ])

  const tooltips: {
    ['en']: {
      title: string
      description: React.ReactChild | React.ReactFragment | React.ReactPortal
    }[]
    ['ru']: {
      title: string
      description: React.ReactChild | React.ReactFragment | React.ReactPortal
    }[]
  } = {
    en: [
      {
        title: 'Minimum image size in bytes',
        description:
          '10240 bytes by default. The API response low_image_weight will return true if the image size is lower than the default value, and false if higher.',
      },
      {
        title: 'Minimum image resolution by short side in pixel',
        description:
          '256 pixels by default. The API response low_image_weight will return true if the shortest side of the image is shorter than the default value, and false if longer.',
      },
      {
        title: 'Maximum exposure',
        description:
          '0.4 by default. The API response max_exposure_score will return “overexposed” if the image exposure value is higher than the default value, and “normal” if lower.',
      },
      {
        title: 'Minimum exposure',
        description:
          '0.05 by default. The API response minimum_exposure_score will return “underexposed” if the image exposure value is lower than the default value, and “normal” if lower.',
      },
      {
        title: 'Minimum image sharpness coefficient',
        description:
          '2 by default. The API response image_blurred will return true if the image sharpness coefficient is lower than the default value, and false if higher.',
      },
    ],
    ru: [
      {
        title: 'Минимальный размер изображения в байтах',
        description: (
          <>
            Если размер меньше указанного числа, параметр low_image_weight
            в&nbsp;ответе вернется со&nbsp;значением true. Если
            больше&nbsp;&mdash; false.
          </>
        ),
      },
      {
        title: 'Минимальный размер изображения в пикселях по короткой стороне',
        description: (
          <>
            Если размер меньше указанного числа, параметр low_image_weight
            в&nbsp;ответе вернется со&nbsp;значением true. Если
            больше&nbsp;&mdash; false.
          </>
        ),
      },
      {
        title: 'Максимальная экспозиция',
        description: (
          <>
            Если яркость больше указанного числа, параметр image_exposure
            в&nbsp;ответе вернется со&nbsp;значением overexposed. Если
            больше&nbsp;&mdash; normal.
          </>
        ),
      },
      {
        title: 'Минимальная экспозиция',
        description: (
          <>
            Если яркость меньше указанного числа, параметр image_exposure
            в&nbsp;ответе вернется со&nbsp;значением underexposed. Если
            больше&nbsp;&mdash; normal.
          </>
        ),
      },
      {
        title: 'Минимальный коэффициент чёткости изображения',
        description: (
          <>
            Если четкость меньше указанного числа, параметр image_blured
            в&nbsp;ответе вернется со&nbsp;значением true. Если
            больше&nbsp;&mdash; false.
          </>
        ),
      },
    ],
  }

  return (
    <div className={style.root}>
      <Button
        onClick={() => setVisible(!isVisible)}
        theme={ButtonTheme.Link}
        className={style.button}
        size={ButtonSize.M}
      >
        {t('Advanced parameters')}
      </Button>
      {isVisible && (
        <div className={style.grid}>
          <div className={style.title}>
            {tooltips[language as 'en' | 'ru'][0].title}
            {/* <Tooltip
              // {...tooltipProps}
              title={tooltips[language as 'en' | 'ru'][0].description}
            >
              <IconButton>
                <QuestionIcon className={style.hint} />
              </IconButton>
            </Tooltip> */}
            <QuestionTooltip
              title={tooltips[language as 'en' | 'ru'][0].description}
            />
          </div>
          <TextInput
            theme={TextInputTheme.White}
            value={min_filesize}
            inputSize="small"
            type="number"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setMinFileSize(Number.parseInt(event.currentTarget.value))
            }
          />
          <div className={style.title}>
            {tooltips[language as 'en' | 'ru'][1].title}
            <QuestionTooltip
              title={tooltips[language as 'en' | 'ru'][1].description}
              className={style.tooltip}
            />
          </div>
          <TextInput
            theme={TextInputTheme.White}
            value={min_shape}
            type="number"
            inputSize="small"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setMinShape(Number.parseInt(event.currentTarget.value))
            }
          />
          <div className={style.title}>
            {tooltips[language as 'en' | 'ru'][2].title}
            <QuestionTooltip
              title={tooltips[language as 'en' | 'ru'][2].description}
            />
          </div>
          <TextInput
            theme={TextInputTheme.White}
            value={max_exposure_score}
            inputSize="small"
            type="number"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setMaxExposureScore(Number.parseInt(event.currentTarget.value))
            }
          />
          <div className={style.title}>
            {tooltips[language as 'en' | 'ru'][3].title}
            <QuestionTooltip
              title={tooltips[language as 'en' | 'ru'][3].description}
            />
          </div>
          <TextInput
            theme={TextInputTheme.White}
            value={min_exposure_score}
            inputSize="small"
            type="number"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setMinExposureScore(Number.parseInt(event.currentTarget.value))
            }
          />
          <div className={style.title}>
            {tooltips[language as 'en' | 'ru'][4].title}
            <QuestionTooltip
              title={tooltips[language as 'en' | 'ru'][4].description}
            />
          </div>
          <TextInput
            theme={TextInputTheme.White}
            value={max_blur_score}
            inputSize="small"
            type="number"
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setBlurScore(Number.parseInt(event.currentTarget.value))
            }
          />
          {isRecognize && language !== 'en' && (
            <>
              <div className={style.title}>
                Нормализация по ФИАС
                <QuestionTooltip title={'Нормализация по ФИАС'} />
              </div>
              <Checkbox
                className={style.checkbox}
                isChecked={normalization_fias}
                onChange={setNormalizationFias}
                theme={CheckboxTheme.Checkmark}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}
