import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RecognizeItemResponse } from '../../@types/api'
import { Api } from '../../api/api'
import UploadStage from '../../components/UploadStage/UploadStage'
import { setAppError } from '../../store/actions'
import { AppState } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import { transformHttpErrorToAppError } from '../../utils/utils'
import style from './PassportRoute.module.css'
import PassportCheck from '../../components/PassportCheck/PassportCheck'

interface Props {}
export default function PassportRoute(props: Props) {
  const [isVisited] = useState(LocalStorage.getVisited())

  const token = useSelector((state: AppState) => state.token)
  const dispatch = useDispatch()

  const docTypes = useSelector((state: AppState) => state.documentTypes)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [images, setImages] = useState<File[]>([])
  const [items, setItems] = useState<RecognizeItemResponse[] | null>(null)

  const handleDrop = async (
    acceptedFiles: File[],
    // docType: string,
    // params: IAdvancedParams,
  ) => {
    setImages(acceptedFiles)
    setLoading(true)
    try {
      const res = await Api.recognizeFile(acceptedFiles[0], 'passport_main')

      if (res.status === 200) {
        setItems(res.data.items)
      } else {
        dispatch(setAppError(transformHttpErrorToAppError(res)))
      }
    } catch (error) {
      dispatch(setAppError(transformHttpErrorToAppError(error)))
    }
    setLoading(false)
  }

  return (
    <div className={style.root}>
      {items && items.length > 0 && items[0].fields ? (
        <PassportCheck fields={items[0].fields} images={images} />
      ) : (
        <UploadStage
          isButtonLoading={isLoading}
          docTypes={docTypes}
          isTutorialOpen={!isVisited}
          onDrop={handleDrop}
          token={token}
        />
      )}
    </div>
  )
}
