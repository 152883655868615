export const Config = {
  BatchRecognizeThreshold: 10,
  RecognizeDocumentsLimit: 1500,
  DocumentTypes: [
    'bank_card',
    'driver_license_1999_paper_back',
    'driver_license_1999_paper_front',
    'driver_license_1999_plastic_back',
    'driver_license_1999_plastic_front',
    'driver_license_2011_back',
    'driver_license_2011_front',
    'driver_license_2014_back',
    'driver_license_japan',
    'empty',
    'inn_organisation',
    'inn_person',
    'insurance_plastic',
    'kgz_passport_main',
    'kgz_passport_plastic_blue',
    'kgz_passport_plastic_red',
    'migration_card',
    'military_id',
    'mts_acts',
    'mts_rfa',
    'ndfl2',
    'not_document',
    'ogrn',
    'ogrnip',
    'other',
    'passport_blank_page',
    'passport_children',
    'passport_last_rf',
    'passport_main',
    'passport_main_handwritten',
    'passport_marriage',
    'passport_military',
    'passport_previous_docs',
    'passport_registration',
    'passport_zero_page',
    'pts_back',
    'pts_front',
    'registration_certificate',
    'rus_passport_global_2007_main',
    'rus_passport_global_2014_main',
    'rus_work_patent',
    'snils_back',
    'snils_front',
    'tjk_passport_main',
    'traffic_accident_notice_back',
    'traffic_accident_notice_front',
    'uzb_passport_main',
    'vehicle_registration_certificate_back',
    'vehicle_registration_certificate_front',
  ],
}
