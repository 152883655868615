import React from 'react'
import style from './RecognizeGrid.module.css'
import { Confidence } from '../../@types/common'
import Badge from '../Badge/Badge'
import { v4 as uuidv4 } from 'uuid'
import VerticalBar from '../Icons/VerticalBar'
import { Mapper } from '../../utils/mapper'
import { AppLanguage } from '../../store/types'
import { useTranslation } from 'react-i18next'
import { BinaryToggler, TextInput } from '@dbrainio/shared-ui'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useState } from 'react'

export interface RecognizeGridItem {
  name: string
  value: string
  confidenceLevel: Confidence
  confidenceNumber: number
}

export interface RecognizeGridProps {
  items: RecognizeGridItem[]
  isReadableFields?: boolean
  isVerbalConfidence?: boolean
  docType: string
  isMobile?: boolean
  editable?: boolean
  onChange?: (key: string, value: string) => void
  onToggleReadableFields?: () => void
  onToggleVerbalConfidence?: () => void
}

const RecognizedGrid = (props: RecognizeGridProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { isMobile, editable, onChange } = props

  const [extended, setExtended] = useState(true)
  const [hover, setHover] = useState(false)

  const getNumericConfidence = (conf: number) => {
    return conf === 0
      ? 'Поле на документе отсутствует'
      : Math.round(conf * 1000) / 1000
  }

  const getConfidence = ({
    confidenceLevel,
    confidenceNumber,
  }: RecognizeGridItem) => {
    if (confidenceNumber === 0) return t('No field detected')

    return props.isVerbalConfidence
      ? t(Mapper.mapConfidenceToReadable(confidenceLevel))
      : getNumericConfidence(confidenceNumber)
  }

  return (
    <div
      className={style.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!isMobile && hover && (
        <div className={style.extended} onClick={() => setExtended(!extended)}>
          {!extended ? (
            <ArrowForwardIosIcon style={{ fontSize: 12, color: '#cecece' }} />
          ) : (
            <ArrowBackIosIcon style={{ fontSize: 12, color: '#cecece' }} />
          )}
        </div>
      )}
      <div className={style.header}>
        <div className={style.gridHeaderItem}>
          {language.toLowerCase() === AppLanguage.Ru && (
            <BinaryToggler
              iconLeft={'РУ'}
              iconRight={'</>'}
              className={style.binary}
              value={!!props.isReadableFields}
              onToggle={() => {
                props.onToggleReadableFields && props.onToggleReadableFields()
              }}
            />
          )}
        </div>
        {isMobile ? <></> : <div className={style.gridHeaderItem}></div>}

        <div className={style.gridHeaderItem}>
          {extended && (
            <BinaryToggler
              iconLeft={<VerticalBar />}
              iconRight={'%'}
              value={!!props.isVerbalConfidence}
              onToggle={() => {
                props.onToggleVerbalConfidence &&
                  props.onToggleVerbalConfidence()
              }}
            />
          )}
        </div>

        {isMobile ? (
          <>
            <div className={style.gridHeaderItem}>{t('Field and Values')}</div>
            <div className={style.gridHeaderItem}>{t('Confidence')}</div>
          </>
        ) : (
          <>
            <div className={style.gridHeaderItem}>{t('Field')}</div>
            <div className={style.gridHeaderItem}>{t('Value')}</div>
            {extended && (
              <div className={style.gridHeaderItem}>{t('Confidence')}</div>
            )}
          </>
        )}
      </div>
      <div className={style.grid}>
        {props.items
          .filter((item, index) =>
            props.isReadableFields ? !!item.value.length : true,
          )
          .map((item, index) => {
            return (
              <div className={style.gridRow} key={index}>
                {isMobile ? (
                  <>
                    <div className={style.gridItem} key={uuidv4()}>
                      <div className={style.mobileName}>
                        {props.isReadableFields &&
                        language.toLowerCase() === AppLanguage.Ru
                          ? Mapper.mapDocumentFieldName(
                              props.docType,
                              item.name,
                            )
                          : item.name}
                      </div>
                      <div className={style.mobileValue}>
                        {editable ? (
                          <TextInput
                            value={item.value}
                            disabled
                            inputSize="small"
                            onChange={(e) =>
                              onChange && onChange(item.name, item.value)
                            }
                          />
                        ) : (
                          item.value
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={style.gridItem} key={uuidv4()}>
                      <div className={style.fieldName}>
                        {props.isReadableFields &&
                        language.toLowerCase() === AppLanguage.Ru
                          ? Mapper.mapDocumentFieldName(
                              props.docType,
                              item.name,
                            )
                          : item.name}
                      </div>
                    </div>
                    <div className={style.gridItem} key={uuidv4()}>
                      <div className={style.fieldValue}>
                        {editable ? (
                          <TextInput
                            value={item.value}
                            inputSize="small"
                            disabled
                            onChange={(e) =>
                              onChange && onChange(item.name, item.value)
                            }
                          />
                        ) : (
                          Mapper.mapRecognizedValue(
                            props.docType,
                            item.name,
                            item.value,
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}

                {extended && (
                  <div className={style.gridItem} key={uuidv4()}>
                    {/* TODO: temporary disabled on bizdev demand */}
                    {item.confidenceNumber !== 0 && (
                      <Badge mode={item.confidenceLevel}>
                        {getConfidence(item)}
                      </Badge>
                    )}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default RecognizedGrid
