import React, { useRef } from 'react'
import style from './Sidebar.module.css'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface Props {}
interface INav {
  text: string
  href: string
}
const getNav = (lang: string) => {
  const nav: INav[] = [
    {
      text: 'Document recognition',
      href: '/',
    },
    {
      text: 'Selfie check',
      href: '/selfie',
    },
    {
      text: 'Face comparison',
      href: '/distance',
    },
    {
      text: 'Fulltext recognition',
      href: '/fulltext',
    },
  ]

  if (!!window._env_.ENABLE_ANTIFRAUD) {
    nav.push({
      text: 'Fraud detection',
      href: '/anti-fraud',
    })
    nav.push({
      text: 'Fraud detection located',
      href: '/anti-fraud-located',
    })
  }

  if (!!window._env_.ENABLE_RUNER) {
    nav.push({
      text: 'RU NER',
      href: '/ru-ner',
    })
  }

  if (!!window._env_.ENABLE_INVOICES) {
    nav.push({
      text: 'Счета-фактуры',
      href: '/invoices',
    })
  }

  if (lang === 'ru') {
    nav.push({
      text: 'Комплексная проверка паспорта',
      href: '/passport',
    })
    nav.push({
      text: 'Handwritten recognition',
      href: '/handwritten',
    })
  }
  return nav
}

export default function Sidebar(props: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const ref = useRef<INav[]>(getNav(language))

  useEffect(() => {
    ref.current = getNav(language)
    return () => {}
  }, [language])
  return (
    <div className={style.root}>
      {ref.current?.map((n, key) => (
        <NavLink
          key={key}
          to={n.href}
          className={({ isActive }) =>
            isActive ? style.activeNavItem : style.navItem
          }
        >
          <span dangerouslySetInnerHTML={{ __html: t(n.text) }}></span>
        </NavLink>
      ))}
    </div>
  )
}
