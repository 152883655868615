import React from 'react'
import Header from '../Header/Header'
import style from './Layout.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store/types'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import Sidebar from '../Sidebar/Sidebar'
import { useLocation } from 'react-router'
import { resetApp } from '../../store/actions'
import { isStudio } from '../../utils/env'
import classnames from 'classnames'
import Hidden from '@mui/material/Hidden'
import Grow from '@mui/material/Grow'

export interface LayoutProps {
  children: React.ReactNode
  isLoading?: boolean
  onReset: () => void
}

export default function Layout(props: LayoutProps) {
  const location = useLocation()
  const dispatch = useDispatch()
  const isNav = useSelector((state: AppState) => state.isNav)

  React.useEffect(() => {
    dispatch(resetApp())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return props.isLoading ? (
    <LoadingScreen />
  ) : (
    <div
      className={classnames(style.root, {
        [style.studio]: isStudio,
      })}
    >
      <div className={style.header}>
        <Header onReset={props.onReset} />
      </div>
      {isStudio ? null : (
        <>
          <Hidden smUp>
            {isNav && (
              <Grow in={isNav}>
                <div className={style.sidebar}>
                  <Sidebar />
                </div>
              </Grow>
            )}
          </Hidden>
          <Hidden xsDown>
            <div className={style.sidebar}>
              <Sidebar />
            </div>
          </Hidden>
        </>
      )}
      <div className={style.content}>{props.children}</div>
    </div>
  )
}
