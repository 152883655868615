import React from 'react'
import style from './BinaryToggler.module.css'
import classnames from 'classnames'
export interface BinaryTogglerProps {
  value: boolean
  iconLeft: React.ReactNode
  iconRight: React.ReactNode
  className?: string
  onToggle: () => void
}

export function BinaryToggler(props: BinaryTogglerProps) {
  return (
    <div
      className={classnames(style.root, props.className, {
        [style.active]: Boolean(props.value)
      })}
      onClick={props.onToggle}
    >
      <div className={style.box}></div>
      <div
        className={[
          style.item,
          ...(props.value ? [style.activeItem] : [])
        ].join(' ')}
      >
        {props.iconLeft}
      </div>
      <div
        className={[
          style.item,
          ...(!props.value ? [style.activeItem] : [])
        ].join(' ')}
      >
        {props.iconRight}
      </div>
    </div>
  )
}
