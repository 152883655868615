import React from 'react';

export default function VerticalBar() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="FAFAFA"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0H8V12H5V0Z" />
      <path d="M0 4H3V12H0V4Z" />
      <path d="M13 6H10V12H13V6Z" />
    </svg>
  );
}
