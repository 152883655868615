import classnames from 'classnames'
import React from 'react'
import style from './MessageBox.module.css'

interface Props {
  error?: boolean
  success?: boolean
  children: React.ReactNode
}
export default function MessageBox({ error, children, success }: Props) {
  return (
    <div className={style.root}>
      <div
        className={classnames(style.box, {
          [style.red]: error,
          [style.green]: success,
        })}
      >
        {children}
      </div>
    </div>
  )
}
