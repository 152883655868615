import React from 'react'
import style from './Polygon.module.css'

interface Props {
  points: number[][]
  textX: number
  textY: number
  text: React.ReactNode
  fontSize: number
  highlighted?: boolean
}

export default function Polygon({
  points,
  fontSize,
  textX,
  textY,
  text,
  highlighted,
}: Props) {
  return (
    <g className={style.root}>
      <polygon
        points={`${points[0][0]},${points[0][1]} ${points[1][0]},${points[1][1]} ${points[2][0]},${points[2][1]} ${points[3][0]},${points[3][1]}`}
        className={style.polygon}
        style={{
          fill: highlighted
            ? 'rgba(198, 12, 49, .2)'
            : 'rgba(19, 158, 239, 0.5)',
          stroke: highlighted ? '#c60c31' : '#139eef',
          strokeWidth: 4,
        }}
      />
      <text
        x={textX}
        y={textY}
        fill="green"
        className={style.hint}
        fontSize={fontSize}
      >
        {text}
      </text>
    </g>
  )
}
