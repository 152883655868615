export const WithHitlDocumentList = [
  'driver_license_1999_paper_back',
  'driver_license_1999_paper_front',
  'driver_license_1999_plastic_front',
  'driver_license_2011_back',
  'driver_license_2011_front',
  'driver_license_2014_back',
  'passport_main',
  'passport_registration',
  'pts_back',
  'pts_front',
  'vehicle_registration_certificate_back',
  'vehicle_registration_certificate_front',
  'passport_registration_handwritten',
  'covid_usa_type_3',
  'traffic_accident_notice_front',
  'traffic_accident_notice_back',
]
