import { IAdvancedParams } from '../components/AdvancedParams/AdvancedParams'
import { IFulltextItem } from '../@types/api'
import {
  DistanceResponseItem,
  DocumentInfo,
  RecognizedFields,
  SelfieResponseItem,
} from '../@types/api'

export const SET_TOKEN = 'SET_TOKEN'
export const SET_CURRENT_STAGE = 'SET_CURRENT_STAGE'
export const START_CLASSIFY = 'START_CLASSIFY'
export const START_RECOGNIZE_ONLY = 'START_RECOGNIZE_ONLY'
export const CLASSIFY_SUCCEEDED = 'CLASSIFY_SUCCEEDED'
export const GET_OPENAPI_SUCCEEDED = 'GET_OPENAPI_SUCCEEDED'
export const RECOGNIZE_SUCCEEDED = 'RECOGNIZE_SUCCEEDED'
export const RECOGNIZE_ONLY_SUCCEEDED = 'RECOGNIZE_ONLY_SUCCEEDED'
export const RECOGNIZE_BATCH_SUCCEEDED = 'RECOGNIZE_BATCH_SUCCEEDED'
export const START_RECOGNIZE = 'START_RECOGNIZE'
export const START_FULLTEXT = 'START_FULLTEXT'
export const START_BATCH_RECOGNIZE = 'START_BATCH_RECOGNIZE'
export const START_GET_OPENAPI = 'START_GET_OPENAPI'
export const SET_APP_ERROR = 'SET_APP_ERROR'
export const RESPONSE_ERROR_HAPPENED = 'RESPONSE_ERROR_HAPPENED'
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR'
export const SET_DOCUMENT_LOADING = 'SET_DOCUMENT_LOADING'
export const RESET_APP = 'RESET_APP'

export const START_SELFIE = 'START_SELFIE'
export const SELFIE_SUCCEEDED = 'SELFIE_SUCCEEDED'

export const COMPLETE_FULLTEXT = 'COMPLETE_FULLTEXT'

export const START_INVOICE = 'START_INVOICE'
export const SET_NAV = 'SET_NAV'
export const INVOICE_SUCCEEDED = 'INVOICE_SUCCEEDED'

export const START_DISTANCE = 'START_DISTANCE'
export const DISTANCE_SUCCEEDED = 'DISTANCE_SUCCEEDED'

export enum AppError {
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  NullResponse = 'NullResponse',
  Other = 'Other',
}

export enum AppLanguage {
  Ru = 'ru',
  En = 'en',
}

export interface AppState {
  images: File[]
  token: string
  selfie: SelfieResponseItem[] | null
  distance: DistanceResponseItem[] | null
  fulltext: IFulltextItem[] | null
  documents: DocumentInfo[] | null
  isButtonLoading: boolean
  isNav: boolean
  appError: string | number | null
  documentTypes: string[]
  isInitializing: boolean
  isRecognizeOnlyButtonLoading: boolean
  recognizeBatchItems: RecognizeBatchItem[] | null
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface StartSelfieAction {
  type: typeof START_SELFIE
  payload: {
    files: File[]
    docType: string
  }
}

export interface StartInvoiceAction {
  type: typeof START_INVOICE
  payload: File[]
}

export interface SetNavAction {
  type: typeof SET_NAV
  payload: boolean
}

export interface StartDistanceAction {
  type: typeof START_DISTANCE
  payload: {
    files: File[]
  }
}
export interface StartClassifyAction {
  type: typeof START_CLASSIFY
  payload: { files: File[]; params: IAdvancedParams }
}

export interface ResetAppAction {
  type: typeof RESET_APP
}

export interface ResponseErrorHappenedAction {
  type: typeof RESPONSE_ERROR_HAPPENED
}

export interface StartRecognizeAction {
  type: typeof START_RECOGNIZE
  payload: StartRecognizePayload
}

export interface StartRecognizeOnlyAction {
  type: typeof START_RECOGNIZE_ONLY
  payload: { files: File[]; params: IAdvancedParams }
}

export interface StartBatchRecognizeAction {
  type: typeof START_BATCH_RECOGNIZE
  payload: File[]
}

export interface StartGetOpenapiAction {
  type: typeof START_GET_OPENAPI
}

export interface ClassifySucceededAction {
  type: typeof CLASSIFY_SUCCEEDED
  payload: DocumentInfo[]
}

export interface StartFulltextAction {
  type: typeof START_FULLTEXT
  payload: File[]
}

export interface CompleteFulltextAction {
  type: typeof COMPLETE_FULLTEXT
  payload: IFulltextItem[]
}

export interface SelfieSucceededAction {
  type: typeof SELFIE_SUCCEEDED
  payload: SelfieResponseItem[]
}

export interface DistanceSucceededAction {
  type: typeof DISTANCE_SUCCEEDED
  payload: DistanceResponseItem[]
}

export interface GetOpenApiSucceededAction {
  type: typeof GET_OPENAPI_SUCCEEDED
  payload: string[]
}

export interface RecognizeSucceededAction {
  type: typeof RECOGNIZE_SUCCEEDED
  payload: RecognizeSucceededPayload
}

export interface RecognizeOnlySucceededAction {
  type: typeof RECOGNIZE_ONLY_SUCCEEDED
  payload: DocumentInfo[]
}

export interface InvoiceSucceededAction {
  type: typeof INVOICE_SUCCEEDED
  payload: InvoiceSucceededPayload
}

export interface RecognizeBatchSucceededAction {
  type: typeof RECOGNIZE_BATCH_SUCCEEDED
  payload: RecognizeBatchSucceededPayload
}

export interface SetAppErrorAction {
  type: typeof SET_APP_ERROR
  payload: string | number
}

export interface SetDocumentLoadingAction {
  type: typeof SET_DOCUMENT_LOADING
  payload: SetDocumentLoadingPayload
}

export interface SetDocumentLoadingPayload {
  id: string
  isLoading: boolean
}

export interface ClearAppErrorAction {
  type: typeof CLEAR_APP_ERROR
}

export interface StartRecognizePayload {
  document: DocumentInfo
  withHitl: boolean
  params: IAdvancedParams
  images?: File[]
}

export interface RecognizeSucceededPayload {
  document: DocumentInfo
  fields: RecognizedFields
}

export interface InvoiceSucceededPayload {
  documents: DocumentInfo[]
}

export interface RecognizeBatchItem {
  docType: string
  fields?: RecognizedFields
  filename: string
  rejected?: boolean
  statusCode?: number
}

export interface RecognizeBatchSucceededPayload {
  items: RecognizeBatchItem[]
}

export type ActionTypes =
  | SetTokenAction
  | ResetAppAction
  | StartClassifyAction
  | ClassifySucceededAction
  | StartRecognizeAction
  | RecognizeSucceededAction
  | SetAppErrorAction
  | ClearAppErrorAction
  | SetDocumentLoadingAction
  | StartBatchRecognizeAction
  | StartGetOpenapiAction
  | GetOpenApiSucceededAction
  | RecognizeBatchSucceededAction
  | StartSelfieAction
  | StartDistanceAction
  | SelfieSucceededAction
  | DistanceSucceededAction
  | SetNavAction
  | StartFulltextAction
  | StartInvoiceAction
  | InvoiceSucceededAction
  | CompleteFulltextAction
  | ResponseErrorHappenedAction
  | StartRecognizeOnlyAction
  | RecognizeOnlySucceededAction
