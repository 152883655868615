import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IAdvancedParams } from '../../components/AdvancedParams/AdvancedParams'
import UploadStage from '../../components/UploadStage/UploadStage'
import { setAppError } from '../../store/actions'
import { AppState } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import style from './RuNerRoute.module.css'
import { Api } from '../../api/api'
import {FraudResult, MspBankResult, RuNerResult} from '../../@types/api'
import { transformHttpErrorToAppError } from '../../utils/utils'
import RuNer from '../../components/RuNer/RuNer'

interface Props {}
export default function RuNerRoute(props: Props) {
  const [isVisited] = useState(LocalStorage.getVisited())

  const token = useSelector((state: AppState) => state.token)
  const dispatch = useDispatch()

  const [result, setResult] = useState<MspBankResult[] | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [images, setImages] = useState<File[]>([])
  const docTypes = useSelector((state: AppState) => state.documentTypes)

  const handleDrop = async (
    acceptedFiles: File[],
    docType: string,
    params: IAdvancedParams,
  ) => {
    setImages(acceptedFiles)
    setLoading(true)
    try {
      const res = await Api.mspBank(acceptedFiles[0])
      if (res.status === 200) {
        setResult(
          res.data.result.map((r) => ({
            ...r,
            entities: r.entities.map((e) => ({
              ...e,
              id: Math.floor(Math.random() * 100) + '',
            })),
          })),
        )
      } else {
        dispatch(setAppError(transformHttpErrorToAppError(res)))
      }
    } catch (error: any) {
      dispatch(setAppError(transformHttpErrorToAppError(error)))
    }
    setLoading(false)
  }

  return (
    <div className={style.root}>
      {result ? (
        <RuNer result={result} images={images} />
      ) : (
        <UploadStage
          isButtonLoading={isLoading}
          docTypes={docTypes}
          isTutorialOpen={!isVisited}
          onDrop={handleDrop}
          token={token}
        />
      )}
    </div>
  )
}
