import style from './Fulltext.module.css'
import Container from '../Container/Container'
import { IFulltextItem } from '../../@types/api'
import FulltextItem from './FulltextItem'

export interface RecognizeStageProps {
  items: IFulltextItem[]
  images: File[]
}

export default function Fulltext({ items, images }: RecognizeStageProps) {
  return (
    <div className={style.root}>
      <Container>
        {items.map((item: IFulltextItem, index) => (
          <FulltextItem item={item} image={images[0]} key={index} />
        ))}
      </Container>
    </div>
  )
}
