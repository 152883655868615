import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { AppLanguage } from '../store/types'

export const i18nInit = (lang: AppLanguage) =>
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: {
            'Openapi is not available. Try later':
              'Openapi is not available. Try later',
            Dbrain: 'Handl',
            'Choose a file(s)': 'Choose a file(s)',
            'or just drag and drop it here': 'or just drag and drop it here',
            'up to 50 pieces':
              'JPEG, PNG, BMP, TIFF, GIF, PDF, DJVU, HEIF, HEIC — less than 10mb',
            'Loading your file': 'Loading your file...',
            Classifying: 'Classifying...',
            'Error 500': 'Something went wrong',
            'Error 503': 'Something went wrong',
            error_500_description:
              'Error 500. Please try again or contact the Handl team',
            'Error 503. Please try again or contact the Handl team':
              'Error 503. Please try again or contact the Handl team',
            InvalidTokenErrorText:
              'You entered an invalid token. Please try again or contact the Handl team',
            Recognize: 'Recognize',
            Recognizing: 'Recognizing...',
            Comparing: 'Comparing...',
            'Счета-фактуры': 'Invoices',
            'Fulltext recognition': 'Fulltext recognition',
            'Empty token': 'Empty token',
            'Use HITL': 'Use crowd (HITL)',
            'HITL hint': `Waiting time ≈ 2-5 minutes. The Humans-In-The-Loop (HITL) feature forwards the information fields with a low confidence score to crowdsourced human validators, who verify that the extracted information is correct, or type in the correct information if it is wrong. Average response time with this feature activated is 2-5 minutes.`,
            'Wrong token format': 'Wrong token format',
            Result: 'Result',
          },
        },
        ru: {
          translation: {
            Result: 'Результат',
            'Fraud detection': 'Антифрод',
            'Fraud detection located': 'Антифрод с ббоксами',
            'Empty token': 'Пустой токен',
            'Wrong token format': 'Неверный формат токена',
            'Openapi is not available. Try later':
              'Openapi недоступно. Попробуйте позже',
            'Enter your token': 'Введите токен',
            'Fulltext recognition': 'Полнотекстовое распознавание',
            Dbrain: 'Dbrain',
            Documentation: 'Документация',
            'Try API': 'Попробовать API',
            'Choose a file(s)': 'Выберите файлы для распознавания',
            'or just drag and drop it here': 'или просто перетащите их сюда',
            'up to 50 pieces':
              'Форматы JPEG, PNG, BMP, TIFF, GIF, PDF, DJVU, HEIF, HEIC — весом до 10 МБ',
            'Multipage files and several documents in one file are supported':
              'Поддерживаются многостраничные файлы и&nbsp;распознавание нескольких документов в&nbsp;одном файле',
            'Loading your file': 'Загружаем...',
            Classifying: 'Классифицируем...',
            'Go back': 'В начало',
            'You entered an invalid token': 'Вы ввели неправильный токен',
            'Please try again or contact the Handl team':
              'Попробуйте еще раз или напишите нам',
            'Error 500': 'Ошибка 500',
            'Error 503': 'Ошибка 503',
            error_500_description:
              'Ошибка 500. Пожалуйста, отправьте код ошибки и файл сотруднику Dbrain',
            'Error 503. Please try again or contact the Handl team':
              'Пожалуйста, отправьте код ошибки и файл сотруднику Dbrain',
            'No fields found in the document': 'Поля в документе не найдены',
            'Please choose a different type of a document. If the document type is correct, please contact the Handl team':
              'Пожалуйста, выберете другой тип документа. Если тип документа правильный, пожалуйста, отправьте документ сотруднику Dbrain',
            'Extract Data': 'Распознать',
            "We can't recognize this document yet, but we're already learning how to":
              'Мы такое еще не умеем распознавать, но уже учимся',
            'Extracted Data': 'Расшифровка',
            'Use HITL': 'С помощью людей (HITL)',
            'Download CSV': 'Скачать CSV',
            'Unknown error': 'Неизвестная ошибка',
            'Please contact us': 'Пожалуйста, свяжитесь с нами',
            Field: 'Поле',
            Confidence: 'Уверенность',
            Value: 'Значение',
            'Field and Values': 'Поля и Значения',
            'No field detected': 'Поле не найдено',
            Low: 'Низкая',
            Medium: 'Средняя',
            High: 'Высокая',
            InvalidTokenErrorText:
              'Токен неверный. Попробуйте еще раз или напишите нам',
            'Go home': 'На главную',
            Classify: 'Классифицировать',
            'Handwritten recognition': 'Распознавание </br> рукописного текста',
            File: 'Файл',
            Size: 'Размер',
            'There is file with size more than 10mb, that will not be processed':
              'Есть файл больше 10 Мб, который не будет обработан в демо-версии',
            'There are files with size more than 10mb, that will not be processed':
              'Есть файлы больше 10 Мб, которые не будут обработаны в демо-версии',
            Recognize: 'Распознать',
            Recognizing: 'Распознаём...',
            Comparing: 'Сравниваем...',
            'Face comparison': 'Сравнение лиц',
            'Selfie check': 'Распознавание селфи',
            'Document recognition': 'Распознавание документов',
            'Advanced parameters': 'Дополнительные параметры',
            'Number of image >= 2': 'Минимум 2 изображения',
            Matched: 'Лица совпали',
            'Not matched': 'Лица не совпали',
            Face: 'Лицо',
            Document: 'Документ',
            'Face on document': 'Лицо на документе',
            Distance: 'Расстояние',
            Faces: 'Лица',
            Reason: 'Причина',
            'Error occurred': 'Произошла ошибка',
            Invoices: 'Инвойсы',
            'Recognize only': 'Только распознать',
            'Document type': 'Тип документа',
            'HITL hint':
              'Время ожидания ≈ 2–5 минут. Классическое распознавание происходит с помощью обученных нейросетевых алгоритмов в течение нескольких секунд. В отдельных случаях можно включить функционал Human-in-the-loop (HITL) и сложные места будут дораспознаны людьми в течение нескольких минут. Ваша информация зашифрована и защищена от кражи.',
          },
        },
      },
      lng: lang,
      fallbackLng: false,
      interpolation: {
        escapeValue: false,
      },
    })
