import React, { useState } from 'react'
import Heading from '../Heading/Heading'
import style from './Tutorial.module.css'
import { motion } from 'framer-motion'
import Grid from '@mui/material/Grid'

interface Props {
  isInitiallyOpen?: boolean
}

export default function Tutorial({ isInitiallyOpen }: Props) {
  const [isOpen, setOpen] = useState(isInitiallyOpen)
  const renderArrow = () => (
    <motion.div
      animate={{ rotate: isOpen ? 180 : 0, transformOrigin: 'center' }}
      transition={{ duration: 0.3 }}
    >
      <svg
        width="24"
        height="15"
        viewBox="0 0 24 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L12 12L22 2"
          stroke="#444444"
          strokeWidth="3"
          strokeMiterlimit="1.5"
        />
      </svg>
    </motion.div>
  )

  return (
    <div className={style.root}>
      <div className={style.button} onClick={() => setOpen(!isOpen)}>
        Каким должен быть документ для&nbsp;распознавания
        <span>{renderArrow()}</span>
      </div>
      {isOpen && (
        <div className={style.content}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className={style.examples}>
                <div
                  className={style.wrong}
                  style={{
                    backgroundImage: `url("/images/wrong1.jpg"`,
                    backgroundSize: 'contain',
                    backgroundPositionX: 42,
                    backgroundPositionY: -16,
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div
                  className={style.right}
                  style={{
                    backgroundImage: `url("/images/right1.jpg"`,
                    backgroundSize: '60%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
              <Heading weight={4}>
                Найдите изображение настоящего документа
              </Heading>
              <div className={style.subheading}>
                Поддельные или «фейковые» документы не&nbsp;распознаются
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className={style.examples}>
                <div
                  className={style.wrong}
                  style={{
                    backgroundImage: `url("/images/wrong2.jpg"`,
                    backgroundSize: '90%',
                    backgroundPositionX: -20,
                    backgroundPositionY: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div
                  className={style.right}
                  style={{
                    backgroundImage: `url("/images/right2.jpg"`,
                    backgroundSize: '70%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
              </div>
              <Heading weight={4}>
                Проверьте, что документ целиком на изображении
              </Heading>
              <div className={style.subheading}>Даже с пустым разворотом</div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className={[style.examples4].join(' ')}>
                <div
                  className={style.wrong}
                  style={{
                    backgroundImage: `url("/images/wrong3_1.jpg"`,
                    backgroundSize: '100%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div
                  className={style.wrong}
                  style={{
                    backgroundImage: `url("/images/wrong3_2.png"`,
                    backgroundSize: '100%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div
                  className={style.wrong}
                  style={{
                    backgroundImage: `url("/images/wrong3_3.png"`,
                    backgroundSize: '100%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <img src="" alt="" />
                </div>
                <div
                  className={style.right}
                  style={{
                    backgroundImage: `url("/images/right3.jpg"`,
                    backgroundSize: '100%',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <img src="" alt="" />
                </div>
              </div>
              <Heading weight={4}>
                Убедитесь, что текст в документе читается
              </Heading>
              <div className={style.subheading}>
                Те поля, которые не&nbsp;будут читаться, не&nbsp;будут
                распознаны системой
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}
