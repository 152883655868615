import React from 'react'
import style from './AppErrorScreen.module.css'
import Heading from '../Heading/Heading'
import Container from '../Container/Container'
import Text from '../Text/Text'
import { useTranslation } from 'react-i18next'
import { Button, ButtonTheme } from '@dbrainio/shared-ui'

export interface AppErrorProps {
  title: string
  subtitle: string
  onButtonClick: () => void
}

const AppErrorScreen = (props: AppErrorProps) => {
  const { t } = useTranslation()
  return (
    <div className={style.root}>
      <Container>
        <div className={style.content}>
          <Heading weight={1}>{props.title}</Heading>
          <Text className={style.subtitle}>{props.subtitle}</Text>
          <Button
            theme={ButtonTheme.BlackOnWhite}
            onClick={props.onButtonClick}
          >
            {t('Go back')}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default AppErrorScreen
