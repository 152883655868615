import style from './TextInput.module.css'
import React, { RefObject } from 'react'
import classNames from 'classnames'
import { VisibilityIcon } from '../VisibilityIcon/VisibilityIcon'

export enum TextInputTheme {
  White = 'White',
  WhiteOnBlue = 'WhiteOnBlue',
  Transparent = 'Transparent'
}

export type TextInputProps = {
  isError?: boolean
  error?: string | boolean
  focus?: boolean
  inputSize?: 'standard' | 'small' | 'large'
  theme?: TextInputTheme
  oneSymboled?: boolean
  inputRef?:
    | RefObject<HTMLInputElement>
    | undefined
    | null
    | ((instance: HTMLInputElement | null) => void)
} & React.HTMLProps<HTMLInputElement>

export class TextInput extends React.Component<TextInputProps> {
  input: RefObject<HTMLInputElement>

  state = {
    isVisible: false
  }

  constructor(props: TextInputProps) {
    super(props)
    this.input = React.createRef()
  }

  getTextInputClassByTheme(theme?: TextInputTheme) {
    switch (theme) {
      case TextInputTheme.WhiteOnBlue:
        return style.whiteOnBlue
      case TextInputTheme.White:
        return style.white
      case TextInputTheme.Transparent:
        return style.transparent
      default:
        return style.default
    }
  }

  componentDidMount() {
    const { focus } = this.props
    if (focus) {
      this.input?.current?.focus()
    }
  }

  render() {
    const {
      isError,
      inputRef,
      theme,
      error,
      className,
      oneSymboled,
      inputSize = 'standard',
      focus,
      ...inputProps
    } = this.props

    const cn = classNames(
      style.root,
      this.getTextInputClassByTheme(theme),
      className,
      {
        [style.error]: !!error || isError,
        [style.oneSymboled]: !!oneSymboled
      }
    )

    return (
      <div className={cn}>
        <input
          {...inputProps}
          type={this.state.isVisible ? 'text' : inputProps.type}
          className={classNames(style.input, {
            [style.standard]: inputSize === 'standard',
            [style.large]: inputSize === 'large',
            [style.small]: inputSize === 'small',
            [style.password]: inputProps.type === 'password'
          })}
          ref={inputRef ? inputRef : this.input}
        />
        {inputProps.type === 'password' && (
          <div
            className={style.toggleVisibility}
            onClick={() => this.setState({ isVisible: !this.state.isVisible })}
          >
            <VisibilityIcon />
          </div>
        )}
        {error && <div className={style.errorText}>{error}</div>}
      </div>
    )
  }
}
