import style from './Handwritten.module.css'
import Container from '../Container/Container'
import { IHandwrittenItem } from '../../@types/api'
import HandwrittenItem from './HandwrittenItem'

export interface RecognizeStageProps {
  items: IHandwrittenItem[]
  images: File[]
}

export default function Handwritten({ items, images }: RecognizeStageProps) {
  return (
    <div className={style.root}>
      <Container>
        <HandwrittenItem items={items} image={images[0]} />
      </Container>
    </div>
  )
}
