import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IAdvancedParams } from '../../components/AdvancedParams/AdvancedParams'
import RecognizeStage from '../../components/RecognizeStage/RecognizeStage'
import UploadStage from '../../components/UploadStage/UploadStage'
import { Config } from '../../config/config'
import {
  resetApp,
  startBatchRecognize,
  startClassify,
  startRecognize,
  startRecognizeOnly,
} from '../../store/actions'
import { AppState } from '../../store/types'
import { LocalStorage } from '../../utils/localStorage'
import style from './DocumentsRoute.module.css'
import BatchToCsvStage from '../../components/BatchToCsvStage/BatchToCsvStage'

interface Props {}
export default function DocumentsRoute(props: Props) {
  const [isVisited] = useState(LocalStorage.getVisited())

  const token = useSelector((state: AppState) => state.token)
  const dispatch = useDispatch()
  const documents = useSelector((state: AppState) => state.documents)
  const images = useSelector((state: AppState) => state.images)
  const docTypes = useSelector((state: AppState) => state.documentTypes)
  const batchItems = useSelector((state: AppState) => state.recognizeBatchItems)

  const handleDrop = (
    acceptedFiles: File[],
    docType: string,
    params: IAdvancedParams,
    isRecognizeOnly?: boolean,
  ) => {
    if (isRecognizeOnly) {
      dispatch(startRecognizeOnly(acceptedFiles, params))
      return
    }

    if (acceptedFiles.length >= Config.BatchRecognizeThreshold) {
      dispatch(startBatchRecognize(acceptedFiles))
      return
    }

    dispatch(startClassify(acceptedFiles, params))
  }

  const onReturn = () => {
    dispatch(resetApp())
  }

  return (
    <div className={style.root}>
      {batchItems ? (
        <BatchToCsvStage recognized={batchItems} onReturnClick={onReturn} />
      ) : documents ? (
        <RecognizeStage
          documents={documents}
          onReset={() => {}}
          onRecognize={(payload) =>
            dispatch(startRecognize({ ...payload, images }))
          }
          images={images}
        />
      ) : (
        <UploadStage
          docTypes={docTypes}
          isTutorialOpen={!isVisited}
          onDrop={handleDrop}
          token={token}
          showRecognizeOnly
        />
      )}
    </div>
  )
}
