import style from './HandwrittenItem.module.css'
import MagnifyImage from '../MagnifyImage/MagnifyImage'
import { IHandwrittenItem } from '../../@types/api'
import MessageBox from '../MessageBox/MessageBox'

export interface FulltextItemProps {
  items: IHandwrittenItem[]
  image: File
}

const Word = ({ word }: { word: string }) => {
  return <div className={style.word}>{word}</div>
}

export default function HandwrittenItem({ items, image }: FulltextItemProps) {
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.imageContainer}>
          {image.type === 'application/pdf' ? (
            <embed
              src={URL.createObjectURL(image)}
              width="100%"
              height="600"
              type="application/pdf"
            ></embed>
          ) : (
            <MagnifyImage
              src={URL.createObjectURL(image)}
              className={style.imageContainer}
            />
          )}
        </div>
        {items.length ? (
          <div className={style.grid}>
            {items.map((w, index) => (
              <Word word={w.text} key={index} />
            ))}
          </div>
        ) : (
          <MessageBox error>Слов не найдено</MessageBox>
        )}
      </div>
    </div>
  )
}
