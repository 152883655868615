import classnames from 'classnames'
import React from 'react'
import style from './QuestionIcon.module.css'

export function QuestionIcon({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return (
    <div className={classnames(style.root, className)} {...props}>
      <svg
        width='8'
        height='10'
        viewBox='0 0 7 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.72 6.41998C3.72 4.97998 6.06 4.97998 6.06 2.93998C6.06 1.61998 4.92 0.47998 3.06 0.47998C0.66 0.47998 0 2.45998 0 2.45998L1.56 3.17998C1.56 3.17998 1.98 2.21998 3.06 2.21998C3.72 2.21998 4.14 2.63998 4.14 3.05998C4.14 4.01998 2.04 4.25998 2.04 5.87998C2.04 6.17998 2.16 6.41998 2.16 6.41998H3.72ZM1.92 8.15998C1.92 8.69998 2.34 9.11998 2.88 9.11998C3.42 9.11998 3.84 8.69998 3.84 8.15998C3.84 7.61998 3.42 7.19998 2.88 7.19998C2.34 7.19998 1.92 7.61998 1.92 8.15998Z'
          fill='#FAFAFA'
        />
      </svg>
    </div>
  )
}
