import {
  SET_TOKEN,
  ActionTypes,
  START_CLASSIFY,
  CLASSIFY_SUCCEEDED,
  START_RECOGNIZE,
  RECOGNIZE_SUCCEEDED,
  SET_APP_ERROR,
  CLEAR_APP_ERROR,
  StartRecognizePayload,
  RecognizeSucceededPayload,
  SET_DOCUMENT_LOADING,
  START_BATCH_RECOGNIZE,
  START_GET_OPENAPI,
  GET_OPENAPI_SUCCEEDED,
  RECOGNIZE_BATCH_SUCCEEDED,
  RecognizeBatchSucceededPayload,
  START_SELFIE,
  START_DISTANCE,
  SELFIE_SUCCEEDED,
  DISTANCE_SUCCEEDED,
  START_INVOICE,
  INVOICE_SUCCEEDED,
  InvoiceSucceededPayload,
  COMPLETE_FULLTEXT,
  RESET_APP,
  RESPONSE_ERROR_HAPPENED,
  START_RECOGNIZE_ONLY,
  RECOGNIZE_ONLY_SUCCEEDED,
} from './types'
import { IAdvancedParams } from '../components/AdvancedParams/AdvancedParams'
import { START_FULLTEXT, SET_NAV } from './types'
import { IFulltextItem } from '../@types/api'
import {
  DistanceResponseItem,
  DocumentInfo,
  SelfieResponseItem,
} from '../@types/api'

// TypeScript infers that this function is returning SendMessageAction
export function setToken(token: string): ActionTypes {
  return {
    type: SET_TOKEN,
    payload: token,
  }
}

export function setAppError(error: string | number): ActionTypes {
  return {
    type: SET_APP_ERROR,
    payload: error,
  }
}

export function responseErrorHappened(): ActionTypes {
  return {
    type: RESPONSE_ERROR_HAPPENED,
  }
}

export function clearAppError(): ActionTypes {
  return {
    type: CLEAR_APP_ERROR,
  }
}

export function startClassify(
  files: File[],
  params: IAdvancedParams,
): ActionTypes {
  return {
    type: START_CLASSIFY,
    payload: {
      files,
      params,
    },
  }
}

export function startRecognizeOnly(
  files: File[],
  params: IAdvancedParams,
): ActionTypes {
  return {
    type: START_RECOGNIZE_ONLY,
    payload: {
      files,
      params,
    },
  }
}

export function startSelfie(files: File[], docType: string): ActionTypes {
  return {
    type: START_SELFIE,
    payload: { files, docType },
  }
}

export function resetApp(): ActionTypes {
  return {
    type: RESET_APP,
  }
}

export function startInvoice(payload: File[]): ActionTypes {
  return {
    type: START_INVOICE,
    payload,
  }
}

export function startFulltext(payload: File[]): ActionTypes {
  return {
    type: START_FULLTEXT,
    payload,
  }
}

export function startDistance(files: File[]): ActionTypes {
  return {
    type: START_DISTANCE,
    payload: { files },
  }
}

export function startRecognize(payload: StartRecognizePayload): ActionTypes {
  return {
    type: START_RECOGNIZE,
    payload: payload,
  }
}

export function startBatchRecognize(payload: File[]): ActionTypes {
  return {
    type: START_BATCH_RECOGNIZE,
    payload: payload,
  }
}

export function startGetOpenapi(): ActionTypes {
  return {
    type: START_GET_OPENAPI,
  }
}

export function classifySucceeded(payload: DocumentInfo[]): ActionTypes {
  return {
    type: CLASSIFY_SUCCEEDED,
    payload,
  }
}

export function selfieSucceeded(payload: SelfieResponseItem[]): ActionTypes {
  return {
    type: SELFIE_SUCCEEDED,
    payload,
  }
}

export function completeFulltext(payload: IFulltextItem[]): ActionTypes {
  return {
    type: COMPLETE_FULLTEXT,
    payload,
  }
}

export function distanceSucceeded(
  payload: DistanceResponseItem[],
): ActionTypes {
  return {
    type: DISTANCE_SUCCEEDED,
    payload,
  }
}

export function setNav(payload: boolean): ActionTypes {
  return {
    type: SET_NAV,
    payload,
  }
}

export function getOpenapiSucceeded(payload: string[]): ActionTypes {
  return {
    type: GET_OPENAPI_SUCCEEDED,
    payload,
  }
}

export function recognizeSucceeded(
  payload: RecognizeSucceededPayload,
): ActionTypes {
  return {
    type: RECOGNIZE_SUCCEEDED,
    payload: payload,
  }
}

export function recognizeOnlySucceeded(payload: DocumentInfo[]): ActionTypes {
  return {
    type: RECOGNIZE_ONLY_SUCCEEDED,
    payload,
  }
}

export function invoiceSucceeded(
  payload: InvoiceSucceededPayload,
): ActionTypes {
  return {
    type: INVOICE_SUCCEEDED,
    payload,
  }
}

export function recognizeBatchSucceeded(
  payload: RecognizeBatchSucceededPayload,
): ActionTypes {
  return {
    type: RECOGNIZE_BATCH_SUCCEEDED,
    payload,
  }
}

export function setDocumentLoading(payload: {
  id: string
  isLoading: boolean
}): ActionTypes {
  return {
    type: SET_DOCUMENT_LOADING,
    payload,
  }
}
